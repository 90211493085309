<template>
<div class="programManagement">
  <a-spin :indicator="indicator" :spinning="spinning">
    <div class="search_Info">
      <div>
      名称:  &nbsp;<a-input v-model="searchName" placeholder="输入名称搜索" style="width: 180px" size="default" @keyup.enter="searchEnter"/>&nbsp;&nbsp;&nbsp;
      <a-button style="margin-left: 10px" type="primary" @click="searchOk" >查询</a-button>
      <a-button style="margin-left: 10px"  @click="searchReset">重置</a-button></div>
    </div>
    <div class="table_Info">
      <a-button type="primary" @click="()=>this.addShow = true">新增</a-button>
      <a-table :columns="columns" style="min-height: 500px" :data-source="InfoTable" :rowKey="record => record.id"   :pagination="false"
      >
        <span slot="operate" slot-scope="text,item,index">
          <a  style="margin-right: 10px" v-if="index !==0" @click="moveUp(item.id)">上移</a>
          <a  style="margin-right: 10px" v-if="index !== InfoTable.length - 1" @click="moveDown(item.id)">下移</a>
          <a @click="editBtn(item.id)" style="margin-right: 10px">修改</a>
          <a-popconfirm
              title="确定删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="delBtn(item.id)"
              @cancel="cancel"
          >
            <a  style="margin-right: 10px">删除</a>
            </a-popconfirm>

      </span>
      </a-table>
      <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      @change="tableChange" />
      </div>
    </div>

    <a-modal v-model="addShow" title="新增" @ok="addOk" >
      <a-form-model :model="addForm" :label-col="labelCol" :rules="rules" :wrapper-col="wrapperCol" ref="add">
        <a-form-model-item prop="title" label="栏目标题">
          <a-input v-model="addForm.title" />
        </a-form-model-item>

        <a-form-model-item prop="code" label="栏目标识">
          <a-input v-model="addForm.code" />
        </a-form-model-item>

      </a-form-model>
    </a-modal>
    <a-modal v-model="editShow" title="修改" @ok="editOk" >
      <a-form-model :model="editForm" :label-col="labelCol" :rules="rules" :wrapper-col="wrapperCol" ref="edit">
        <a-form-model-item prop="title" label="栏目标题">
          <a-input v-model="editForm.title" />
        </a-form-model-item>

        <a-form-model-item prop="code" label="栏目标识">
          <a-input v-model="editForm.code" />
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </a-spin>
</div>
</template>

<script>
import {
  delProgram,
  getProgram,
  getProgramId,
  postProgram, postProgramDown,
  postProgramUp,
  putProgram
} from "@/service/dataDictionary_api";


export default {
  name: "programManagement",
  data(){
    return{
      spinning: false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      searchName:'',
      addShow:false,
      editShow:false,
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      columns:[
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          customRender: (text,record,index) => `${index+1}`,
          align:'center',
          width:'8%',
        },
        {
          title: '名称',
          dataIndex: 'title',
        },
        {
          title: '操作',
          width: '20%',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      InfoTable:[
      ],
      editForm:{},
      addForm:{},
      rules:{
        title: [
          { required: true, message: '请输入栏目标题', trigger: 'blur' }],
        code: [
          { required: true, message: '请输入栏目标识', trigger: 'blur' }],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"栏目管理")
  },
  mounted() {
    this.getProgramList()
  },
  methods:{
    //获取栏目列表
    async getProgramList(pageNum,title){
      this.spinning = true
      const Info = await getProgram(pageNum,title)
      if (Info.code === 0 ){
        this.InfoTable = Info.data.wyYs
        this.pagination.total = Info.data.count
      }else {
        this.$message.warn('连接失败,请联系管理员' + Info.message)
      }
      this.spinning = false
    },
    //分页跳转
    tableChange(e){
      this.getProgramList(e)
    },
    searchEnter(){
      this.searchOk()
    },
    //确认搜索
    searchOk(){
      this.getProgramList(1,this.searchName)
      this.pagination.current = 1
    },
    //搜索重置
    searchReset(){
      this.getProgramList()
      this.pagination.current = 1
      this.searchName = ''
    },
    moveUp(id){
      postProgramUp(id)
      this.timer = setTimeout(()=>{
        this.getProgramList(this.pagination.current)
      },1000);
    },
    moveDown(id){
      postProgramDown(id)
      this.timer = setTimeout(()=>{
        this.getProgramList(this.pagination.current)
      },1000);
    },
    //新增
    async addOk(){
      this.$refs.add.validate(async valid =>{
        if (valid){
          const data = {
            title : this.addForm.title,
            code : this.addForm.code
          }
          const add = await postProgram(data)
          if (add.code===0){
            //刷新列表
            await this.getProgramList(this.pagination.current)
            this.$message.success("添加成功")
            //清空输入框
            this.$refs.add.resetFields();
            //关闭对话框
            this.addShow= false;
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    async editBtn(id){
      const Info = await getProgramId(id)
      this.editForm = Info.data
      this.editId = id
      this.editShow = true
    },
    //修改
    async editOk(){
      this.$refs.edit.validate(async valid =>{
        if (valid){
          const data = {
            title : this.editForm.title,
            code : this.editForm.code
          }
          const edit = await putProgram(this.editId,data)
          if (edit.code===0){
            //刷新列表
            await this.getProgramList(this.pagination.current)
            this.$message.success("修改成功")
            //清空输入框
            this.$refs.edit.resetFields();
            //关闭对话框
            this.editShow= false;
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //删除栏目
    async delBtn(id){
      const Info = await delProgram(id)
      if (Info.code === 0){
        await this.getProgramList(this.pagination.current)
        this.$message.success('删除成功')
      }else {
        this.$message.warn('删除失败')
      }
    },
    cancel(){
      this.$message.warning('取消删除');
    }

  },
}
</script>

<style lang="scss" scoped>
.programManagement{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .search_Info{
    border-radius: 3px;
    background-color: white;
    height: 80px;
    width: 98%;
    margin: 0 auto;
    line-height: 80px;
    display: flex;
    padding: 0 15px 0 15px;
  }
  .table_Info{
    height:100%;
    width: 98%;
    margin: 10px auto;
    padding: 15px;
    background-color: white;

  }
}
</style>
